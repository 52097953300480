import * as Yup from "yup";

const PHONE_REG_EXP = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export default Yup.object().shape({
	"DBA": Yup.string()
		.min(2, "Too Short!")
		.max(100, "Too Long!")
		.required("Required"),
	"Business Address": Yup.string()
		.min(2, "Too Short!")
		.max(250, "Too Long!")
		.required("Required"),
	"Business City": Yup.string()
		.min(2, "Too Short!")
		.max(100, "Too Long!")
		.required("Required"),
	"Business State": Yup.string()
		.required("Required"),
	"Business Zip": Yup.string()
		.required("Required"),
	"Contact Name": Yup.string()
		.min(2, "Too Short!")
		.max(100, "Too Long!")
		.required("Required"),
	"Business Email": Yup.string()
		.email("Invalid Email")
		.required("Required"),
	"Business Phone": Yup.string()
		.matches(PHONE_REG_EXP, "Phone number is not valid")
		.required(),
	"Legal Name": Yup.string()
		.min(2, "Too Short!")
		.max(100, "Too Long!")
		.required("Required"),
	"Corporate Address": Yup.string()
		.min(2, "Too Short!")
		.max(250, "Too Long!"),
	"Corporate City": Yup.string()
		.min(2, "Too Short!")
		.max(100, "Too Long!"),
	"Corporate State": Yup.string(),
	"Corporate Zip": Yup.string(),
	"Description of Business": Yup.string()
		.min(2, "Too Short!")
		.max(300, "Too Long!")
		.required("Required"),
	"Date Business Started": Yup.date(),
	"Entity Type": Yup.string()
		.required("Required"),
	"Federal Tax ID": Yup.string()
		.required("Required"),
	"Website": Yup.string()
		.required("Required"),
	"First Name": Yup.string()
		.min(2, "Too Short!")
		.max(100, "Too Long!")
		.required("Required"),
	"Last Name": Yup.string()
		.min(2, "Too Short!")
		.max(100, "Too Long!")
		.required("Required"),
	"Title": Yup.string()
		.required("Required"),
	"Email": Yup.string()
		.email("Invalid Email")
		.required("Required"),
	"Phone": Yup.string()
		.matches(PHONE_REG_EXP, "Phone number is not valid")
		.required(),
	"Home Address": Yup.string()
		.min(2, "Too Short!")
		.max(250, "Too Long!")
		.required("Required"),
	"City": Yup.string()
		.min(2, "Too Short!")
		.max(100, "Too Long!")
		.required("Required"),
	"State": Yup.string()
		.required("Required"),
	"Zip": Yup.string()
		.required("Required"),
	"Social Security Number": Yup.string()
		.min(2, "Too Short!")
		.max(100, "Too Long!")
		.required("Required"),
	"Date of Birth": Yup.date()
		.required("Required"),
	"Routing #": Yup.string(),
	"Account/DDA #": Yup.string(),
	"Average Ticket": Yup.number(),
	"High Ticket": Yup.number(),
	"Monthly Volume": Yup.number(),
	"Card Present (Swiped)": Yup.number(),
	"Card Present (Keyed)": Yup.number(),
	"Mail / Telephone Order": Yup.number(),
	"Internet": Yup.number(),
	"Terminal Type": Yup.string(),
	"Quantity": Yup.number(),
	"Auto Batch Close Time": Yup.string(),
	"Notes": Yup.string(),
	"Business Check Document": Yup.mixed(),
	"Identification Document": Yup.mixed(),
	"Supporting Document 1": Yup.mixed(),
	"Supporting Document 2": Yup.mixed(),
	"Supporting Document 3": Yup.mixed()
});