export default [
	{
		value: "N/A",
		text: "N/A"
	},
	{
		value: "President",
		text: "President"
	},
	{
		value: "Vice President",
		text: "Vice President"
	},
	{
		value: "Treasurer",
		text: "Treasurer"
	},
	{
		value: "Owner",
		text: "Owner"
	},
	{
		value: "Partner",
		text: "Partner"
	},
	{
		value: "CEO",
		text: "CEO"
	},
	{
		value: "Secretary",
		text: "Secretary"
	},
	{
		value: "Director",
		text: "Director"
	},
];