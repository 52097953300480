import {faker} from "@faker-js/faker";

export const formFields = {
	"DBA": "",
	"Business Address": "",
	"Business City": "",
	"Business State": "",
	"Business Zip": "",
	"Contact Name": "",
	"Business Email": "",
	"Business Phone": "",
	"Legal Name": "",
	"Corporate Address": "",
	"Corporate City": "",
	"Corporate State": "",
	"Corporate Zip": "",
	"Description of Business": "",
	"Date Business Started": "",
	"Entity Type": "",
	"Federal Tax ID": "",
	"Website": "",
	"First Name": "",
	"Last Name": "",
	"Title": "",
	"Email": "",
	"Phone": "",
	"Home Address": "",
	"City": "",
	"State": "",
	"Zip": "",
	"Social Security Number": "",
	"Date of Birth": "",
	"Routing #": "",
	"Account/DDA #": "",
	"Average Ticket": "",
	"High Ticket": "",
	"Monthly Volume": "",
	"Card Present (Swiped)": "",
	"Card Present (Keyed)": "",
	"Mail / Telephone Order": "",
	"Internet": "",
	"Terminal Type": "",
	"Quantity": "",
	"Auto Batch Close Time": "",
	"Notes": "",
	"Business Check Document": "",
	"Identification Document": "",
	"Supporting Document 1": "",
	"Supporting Document 2": "",
	"Supporting Document 3": ""
};

export const mockFieldsList = {
	"DBA": faker.name.fullName(),
	"Business Address": faker.address.streetAddress(true),
	"Business City": faker.address.city(),
	"Business State": faker.address.stateAbbr(),
	"Business Zip": faker.address.zipCode(),
	"Contact Name": faker.name.fullName(),
	"Business Email": faker.internet.email(),
	"Business Phone": faker.phone.number("##########"),
	"Legal Name": faker.name.fullName(),
	"Corporate Address": faker.address.streetAddress(true),
	"Corporate City": faker.address.city(),
	"Corporate State": faker.address.stateAbbr(),
	"Corporate Zip": faker.address.zipCode(),
	"Description of Business": faker.lorem.words(15),
	"Date Business Started": faker.date.past(5).toISOString().split(".")[0],
	"Entity Type": "Sole Prop",
	"Federal Tax ID": faker.random.alphaNumeric(10),
	"Website": faker.internet.domainName(),
	"First Name": faker.name.firstName(),
	"Last Name": faker.name.lastName(),
	"Title": "President",
	"Email": faker.internet.email(),
	"Phone": faker.phone.number("##########"),
	"Home Address": faker.address.streetAddress(true),
	"City": faker.address.city(),
	"State": faker.address.stateAbbr(),
	"Zip": faker.address.zipCode(),
	"Social Security Number": faker.random.alphaNumeric(15),
	"Date of Birth": faker.date.birthdate().toISOString().split(".")[0],
	"Routing #": faker.finance.routingNumber(),
	"Account/DDA #": faker.finance.account(),
	"Average Ticket": faker.random.numeric(3),
	"High Ticket": faker.random.numeric(3),
	"Monthly Volume": faker.random.numeric(3),
	"Card Present (Swiped)": faker.random.numeric(3),
	"Card Present (Keyed)": faker.random.numeric(2),
	"Mail / Telephone Order": faker.random.numeric(3),
	"Internet": faker.random.numeric(2),
	"Terminal Type": "Point of Sale System",
	"Quantity": faker.random.numeric(1),
	"Auto Batch Close Time": faker.date.soon().toISOString().split(".")[0],
	"Notes": faker.lorem.words(50),
	"Business Check Document": "",
	"Business Check Document File": "",
	"Identification Document": "",
	"Identification Document File": "",
	"Supporting Document 1": "",
	"Supporting Document 1 File": "",
	"Supporting Document 2": "",
	"Supporting Document 2 File": "",
	"Supporting Document 3": "",
	"Supporting Document 3 File": ""
};
