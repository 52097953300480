export default [
	{
		value: "Standalone Terminal",
		text: "Standalone Terminal"
	},
	{
		value: "Virtual Terminal",
		text: "Virtual Terminal"
	},
	{
		value: "Point of Sale System",
		text: "Point of Sale System"
	},
];