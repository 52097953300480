import React, {useState} from "react";
import "./App.css";
import {Formik} from "formik";
import {Alert, Button, Card, Col, Container, Image, Row} from "react-bootstrap";
import FormField from "./FormField";
import {UsaStates} from "usa-states";
import entityList from "./entity-list";
import titleList from "./title-list";
import terminalTypeList from "./terminal-type-list";
import {formFields, mockFieldsList} from "./form-fields-list";
import FormSchema from "./FormSchema";

const USA_STATES = new UsaStates();

// const FILE_FIELDS = [
// 	"Business Check Document",
// 	"Identification Document",
// 	"Supporting Document 1",
// 	"Supporting Document 2",
// 	"Supporting Document 3"
// ];

function App() {
	const searchParams = window.location.search.slice(1).split("&").reduce((a, c) => {
		const sp = c.split("=");
		a[sp[0]] = sp[1];
		return a;
	}, {});
	const [hideFields] = useState(false);
	return (
		<Formik
			initialValues={searchParams.t ? mockFieldsList : formFields}
			validationSchema={FormSchema}
			onSubmit={(values, {setSubmitting}) => {
				let formData = new FormData();

				Object.entries(values).map(([k, V]) => {
					// if (FILE_FIELDS.includes(k))
					// 	return;
					formData.append(k, V);
				});

				try {
					setSubmitting(true);
					fetch("http://localhost:8000/send", {
						method: "POST",
						body: formData,
						dataType: "jsonp"
					})
						.then(response => {
							if (response.status === 200 || response.status === 201) {
								alert("successfully uploaded file");
							} else {
								alert("failed to upload");
							}
							setSubmitting(false);
						});
				} catch (e) {
					console.log(e);
					alert("some error occured while uploading file");
					setSubmitting(false);
				}
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				setFieldValue,
				isSubmitting,
				/* and other goodies */
			}) => (
				<form onSubmit={handleSubmit}>
					<Container>
						<Row className={"mt-5"}>
							<Col>
								<p>For quick verification and call from an agent, please complete this page.</p>
							</Col>
						</Row>
						<Row className={"mt-3"}>
							<Col>
								<Card className={"shadow border-light"}>
									<Card.Body>
										<Card.Title>DBA Address</Card.Title>
										<Row>
											<Col>
												<FormField
													type={"text"}
													handleBlur={handleBlur}
													name={"DBA"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormField
													type={"text"}
													handleBlur={handleBlur}
													name={"Business Address"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
													iconRight={"geo-alt-fill"}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormField
													type={"text"}
													handleBlur={handleBlur}
													name={"Business City"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
												/>
											</Col>
											<Col>
												<FormField
													handleBlur={handleBlur}
													name={"Business State"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
													listData={USA_STATES.states.map(s => ({
														value: s.abbreviation,
														text: s.abbreviation
													}))}
												/>
											</Col>
											<Col>
												<FormField
													type={"text"}
													handleBlur={handleBlur}
													name={"Business Zip"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormField
													type={"text"}
													handleBlur={handleBlur}
													name={"Contact Name"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
												/>
											</Col>
											<Col>
												<FormField
													type={"email"}
													handleBlur={handleBlur}
													name={"Business Email"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
													iconRight={"envelope"}
												/>
											</Col>
											<Col>
												<FormField
													type={"tel"}
													handleBlur={handleBlur}
													name={"Business Phone"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
												/>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col>
							<Col>
								<Card className={"shadow border-light"}>
									<Card.Body>
										<Card.Title>Legal Address</Card.Title>
										<Row>
											<Col>
												<FormField
													type={"text"}
													handleBlur={handleBlur}
													name={"Legal Name"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormField
													type={"text"}
													handleBlur={handleBlur}
													name={"Corporate Address"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													iconRight={"geo-alt-fill"}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormField
													type={"text"}
													handleBlur={handleBlur}
													name={"Corporate City"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
												/>
											</Col>
											<Col>
												<FormField
													handleBlur={handleBlur}
													name={"Corporate State"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													listData={USA_STATES.states.map(s => ({
														value: s.abbreviation,
														text: s.abbreviation
													}))}
												/>
											</Col>
											<Col>
												<FormField
													type={"text"}
													handleBlur={handleBlur}
													name={"Corporate Zip"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
												/>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col>
						</Row>
						<Row className={"mt-5"}>
							<Col>
								<Card className={"shadow border-light"}>
									<Card.Body>
										<Card.Title>Business Information</Card.Title>
										<Row>
											<Col>
												<FormField
													type={"text"}
													handleBlur={handleBlur}
													name={"Description of Business"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormField
													type={"datetime-local"}
													max={(new Date()).toISOString().split(".")[0]}
													handleBlur={handleBlur}
													name={"Date Business Started"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormField
													type={"text"}
													handleBlur={handleBlur}
													name={"Entity Type"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
													listData={entityList}
												/>
											</Col>
											<Col>
												<FormField
													type={"text"}
													handleBlur={handleBlur}
													name={"Federal Tax ID"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormField
													type={"text"}
													handleBlur={handleBlur}
													name={"Website"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
												/>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col>
						</Row>
						<Row className={"mt-5"}>
							<Col>
								<Card className={"shadow border-light"}>
									<Card.Body>
										<Card.Title>Owner</Card.Title>
										<Row>
											<Col>
												<FormField
													type={"text"}
													handleBlur={handleBlur}
													name={"First Name"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
												/>
											</Col>
											<Col>
												<FormField
													type={"text"}
													handleBlur={handleBlur}
													name={"Last Name"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormField
													type={"text"}
													handleBlur={handleBlur}
													name={"Title"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
													listData={titleList}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormField
													type={"email"}
													handleBlur={handleBlur}
													name={"Email"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
													iconRight={"envelope"}
												/>
											</Col>
											<Col>
												<FormField
													type={"tel"}
													handleBlur={handleBlur}
													name={"Phone"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormField
													type={"text"}
													handleBlur={handleBlur}
													name={"Home Address"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
													iconRight={"geo-alt-fill"}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormField
													type={"text"}
													handleBlur={handleBlur}
													name={"City"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
												/>
											</Col>
											<Col>
												<FormField
													handleBlur={handleBlur}
													name={"State"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
													listData={USA_STATES.states.map(s => ({
														value: s.abbreviation,
														text: s.abbreviation
													}))}
												/>
											</Col>
											<Col>
												<FormField
													type={"text"}
													handleBlur={handleBlur}
													name={"Zip"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormField
													type={"text"}
													handleBlur={handleBlur}
													name={"Social Security Number"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
													required={true}
												/>
											</Col>
											<Col>
												<FormField
													type={"datetime-local"}
													max={(new Date()).toISOString().split(".")[0]}
													handleBlur={handleBlur}
													name={"Date of Birth"}
													handleChange={handleChange}
													values={values}
													errors={errors}
													touched={touched}
												/>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col>
						</Row>
						{
							!hideFields && (
								<>
									<Row className={""} style={{marginTop: "5rem"}}>
										<Col>
											<Card className={"shadow border-light"}>
												<Card.Body>
													<Card.Title>Business Profile</Card.Title>
													<Row>
														<Col>
															<FormField
																type={"text"}
																handleBlur={handleBlur}
																name={"Routing #"}
																handleChange={handleChange}
																values={values}
																errors={errors}
																touched={touched}
																required={true}
															/>
														</Col>
														<Col>
															<FormField
																type={"text"}
																handleBlur={handleBlur}
																name={"Account/DDA #"}
																handleChange={handleChange}
																values={values}
																errors={errors}
																touched={touched}
																required={true}
															/>
														</Col>
													</Row>
													<Row>
														<Col>
															<Image
																src={process.env.PUBLIC_URL + "/bank.png"}
																fluid={true}
																className={"mt-3"}
															/>
														</Col>
													</Row>
												</Card.Body>
											</Card>
										</Col>
										<Col>
											<Card className={"shadow border-light"}>
												<Card.Body>
													<Card.Title>Sales Profile</Card.Title>
													<Row>
														<Col>
															<FormField
																type={"number"}
																handleBlur={handleBlur}
																name={"Average Ticket"}
																handleChange={handleChange}
																values={values}
																errors={errors}
																touched={touched}
																iconLeft={"currency-dollar"}
																step={0.01}
															/>
														</Col>
														<Col>
															<FormField
																type={"number"}
																handleBlur={handleBlur}
																name={"High Ticket"}
																handleChange={handleChange}
																values={values}
																errors={errors}
																touched={touched}
																iconLeft={"currency-dollar"}
																step={0.01}
															/>
														</Col>
														<Col>
															<FormField
																type={"number"}
																handleBlur={handleBlur}
																name={"Monthly Volume"}
																handleChange={handleChange}
																values={values}
																errors={errors}
																touched={touched}
																iconLeft={"currency-dollar"}
																step={0.01}
															/>
														</Col>
													</Row>
													<Row>
														<Col>
															<Alert variant={"warning"}>
                                                                Estimate your sales percentage below by category. Total must
                                                                be
                                                                100%.
															</Alert>
														</Col>
													</Row>
													<Row>
														<Col>
															<FormField
																type={"number"}
																handleBlur={handleBlur}
																name={"Card Present (Swiped)"}
																handleChange={handleChange}
																values={values}
																errors={errors}
																touched={touched}
																iconRight={"percent"}
																step={0.01}
															/>
														</Col>
														<Col>
															<FormField
																type={"number"}
																handleBlur={handleBlur}
																name={"Card Present (Keyed)"}
																handleChange={handleChange}
																values={values}
																errors={errors}
																touched={touched}
																iconRight={"percent"}
																step={0.01}
															/>
														</Col>
													</Row>
													<Row>
														<Col>
															<FormField
																type={"number"}
																handleBlur={handleBlur}
																name={"Mail / Telephone Order"}
																handleChange={handleChange}
																values={values}
																errors={errors}
																touched={touched}
																iconRight={"percent"}
																step={0.01}
															/>
														</Col>
														<Col>
															<FormField
																type={"number"}
																handleBlur={handleBlur}
																name={"Internet"}
																handleChange={handleChange}
																values={values}
																errors={errors}
																touched={touched}
																iconRight={"percent"}
																step={0.01}
															/>
														</Col>
													</Row>
												</Card.Body>
											</Card>
										</Col>
									</Row>
									<Row className={"mt-5"}>
										<Col>
											<Card className={"shadow border-light"}>
												<Card.Body>
													<Card.Title>Equipment</Card.Title>
													<Row>
														<Col>
															<FormField
																type={"text"}
																handleBlur={handleBlur}
																name={"Terminal Type"}
																handleChange={handleChange}
																values={values}
																errors={errors}
																touched={touched}
																listData={terminalTypeList}
															/>
														</Col>
													</Row>
													<Row>
														<Col>
															<FormField
																type={"number"}
																handleBlur={handleBlur}
																name={"Quantity"}
																handleChange={handleChange}
																values={values}
																errors={errors}
																touched={touched}
															/>
														</Col>
													</Row>
													<Row>
														<Col>
															<FormField
																type={"text"}
																handleBlur={handleBlur}
																name={"Auto Batch Close Time"}
																handleChange={handleChange}
																values={values}
																errors={errors}
																touched={touched}
															/>
														</Col>
													</Row>
													<Row>
														<Col>
															<FormField
																type={"text"}
																handleBlur={handleBlur}
																name={"Notes"}
																handleChange={handleChange}
																values={values}
																errors={errors}
																touched={touched}
															/>
														</Col>
													</Row>
												</Card.Body>
											</Card>
										</Col>
									</Row>
									<Row className={"mt-5"}>
										<Col>
											<Card className={"shadow border-light"}>
												<Card.Body>
													<Card.Title>Supporting Documents</Card.Title>
													<Row>
														<Col>
															<Alert variant={"warning"}>
                                                                Copy of your business voided check, using your mobile phone
                                                                camera
                                                                or
                                                                scanned copy in PDF, JPG or PNG format
															</Alert>
															<FormField
																type={"file"}
																handleBlur={handleBlur}
																name={"Business Check Document"}
																handleChange={(event) => {
																	setFieldValue("Business Check Document File", event.currentTarget.files[0]);
																	handleChange(event);
																}}
																values={values}
																errors={errors}
																touched={touched}
																required={true}
															/>
														</Col>
														<Col>
															<Alert variant={"warning"}>
                                                                Copy of your state ID, driver license and/or passport using
                                                                your
                                                                mobile
                                                                phone camera or, scanned copy in PDF, JPG or PNG format
															</Alert>
															<FormField
																type={"file"}
																handleBlur={handleBlur}
																name={"Identification Document"}
																handleChange={(event) => {
																	setFieldValue("Identification Document File", event.currentTarget.files[0]);
																	handleChange(event);
																}}
																values={values}
																errors={errors}
																touched={touched}
																required={true}
															/>
														</Col>
													</Row>
													<Row>
														<Col>
															<Alert variant={"warning"}>
                                                                Additional Supporting Documents: SS-4, Business License,
                                                                Credit Card
                                                                Processing Statements and/or Bank Statements
															</Alert>
														</Col>
													</Row>
													<Row>
														<Col>
															<FormField
																type={"file"}
																handleBlur={handleBlur}
																name={"Supporting Document 1"}
																handleChange={(event) => {
																	setFieldValue("Supporting Document 1 File", event.currentTarget.files[0]);
																	handleChange(event);
																}}
																values={values}
																errors={errors}
																touched={touched}
															/>
														</Col>
														<Col>
															<FormField
																type={"file"}
																handleBlur={handleBlur}
																name={"Supporting Document 2"}
																handleChange={(event) => {
																	setFieldValue("Supporting Document 2 File", event.currentTarget.files[0]);
																	handleChange(event);
																}}
																values={values}
																errors={errors}
																touched={touched}
															/>
														</Col>
														<Col>
															<FormField
																type={"file"}
																handleBlur={handleBlur}
																name={"Supporting Document 3"}
																handleChange={(event) => {
																	setFieldValue("Supporting Document 3 File", event.currentTarget.files[0]);
																	handleChange(event);
																}}
																values={values}
																errors={errors}
																touched={touched}
															/>
														</Col>
													</Row>
												</Card.Body>
											</Card>
										</Col>
									</Row>
								</>
							)
						}
						<Row className={"mt-5 mb-5"}>
							<Col className={"d-flex flex-row justify-content-between align-items-center"}>
								{
									!hideFields &&
                                    <p className={"m-0 text-danger fw-bold h5"}>DOCUMENTS CAN BE SUBMITTED NOW OR
                                        LATER OR JUST
                                        CLICK &quot;SUBMIT FORM&quot;</p>
								}
								<Button type={"submit"} variant={"primary"} size={"lg"} disabled={isSubmitting}>
									<i className={"bi bi-check-lg"}/> {isSubmitting ? "Submitting. Please Wait." : "Submit Form"}
								</Button>
							</Col>
						</Row>
					</Container>
				</form>
			)}
		</Formik>
	);
}

export default App;
