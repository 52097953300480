export default [
	{
		value: "N/A",
		text: "N/A"
	},
	{
		value: "Sole Prop",
		text: "Sole Prop"
	},
	{
		value: "LLC",
		text: "LLC"
	},
	{
		value: "Partnership",
		text: "Partnership"
	},
	{
		value: "Ltd Partnership",
		text: "Ltd Partnership"
	},
	{
		value: "Government",
		text: "Government"
	},
	{
		value: "Trust",
		text: "Trust"
	},
	{
		value: "Professional Assoc",
		text: "Professional Assoc"
	},
	{
		value: "Political Org",
		text: "Political Org"
	},
	{
		value: "Public Corp",
		text: "Public Corp"
	},
	{
		value: "Private Corp",
		text: "Private Corp"
	},
	{
		value: "Non Profit Corp",
		text: "Non Profit Corp"
	},
	{
		value: "Financial Institution",
		text: "Financial Institution"
	},
];