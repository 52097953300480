import React from "react";
import {Form, InputGroup} from "react-bootstrap";
import PropTypes from "prop-types";

FormField.propTypes = {
	name: PropTypes.string.isRequired,
	type: PropTypes.string,
	required: PropTypes.bool,
	listData: PropTypes.array,
	iconLeft: PropTypes.string,
	iconRight: PropTypes.string,
	handleChange: PropTypes.func.isRequired,
	handleBlur: PropTypes.func.isRequired,
	values: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	touched: PropTypes.object.isRequired,
};

function FormField({
	name,
	type,
	required,
	listData,
	iconLeft,
	iconRight,
	handleChange,
	handleBlur,
	values,
	errors,
	touched,
	...controlProps
}) {
	return (
		<Form.Group className="" controlId={name}>
			<Form.Label>{name} {required && <span className={"text-danger"}>*</span>} </Form.Label>
			{listData ? (
				<Form.Select
					name={name}
					onChange={handleChange}
					onBlur={handleBlur}
					value={values[name]}
					isInvalid={errors[name] && touched[name]}
					{...controlProps}
				>
					<option value={""}>Select {name}</option>
					{
						listData.map(d => (
							<option key={d.value} value={d.value}>{d.text}</option>
						))
					}
				</Form.Select>
			) : (
				<>
					<InputGroup
						className={`${errors[name] && touched[name] ? "is-invalid" : ""} ${iconLeft ? "has-left-icon" : ""} ${iconRight ? "has-right-icon" : ""}`}
					>
						{iconLeft && <InputGroup.Text><i className={`bi bi-${iconLeft}`}/></InputGroup.Text>}
						<Form.Control
							name={name}
							onChange={handleChange}
							onBlur={handleBlur}
							value={values[name]}
							isInvalid={errors[name] && touched[name]}
							type={type}
							{...controlProps}
						/>
						{iconRight && <InputGroup.Text><i className={`bi bi-${iconRight}`}/></InputGroup.Text>}
					</InputGroup>
				</>
			)}
			<div className="invalid-feedback">
				{errors[name]}
			</div>
		</Form.Group>
	);
}

export default FormField;